import { useState, useCallback } from 'react';

const usePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    handleOpenPopup,
    handleClosePopup,
  };
};

export default usePopup;
