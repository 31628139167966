import { useEffect, useRef } from 'react';

export const useCursor = () => {
  const cursorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const foregroundRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      const containerRect = containerRef.current!.getBoundingClientRect();
      const cursorSize = cursorRef.current!.clientWidth / 2;
      let x = e.clientX - containerRect.left;
      let y = e.clientY - containerRect.top;

      x = Math.max(cursorSize, Math.min(x, containerRect.width - cursorSize));
      y = Math.max(cursorSize, Math.min(y, containerRect.height - cursorSize));

      cursorRef.current!.style.left = `${x}px`;
      cursorRef.current!.style.top = `${y}px`;

      const fgRect = foregroundRef.current!.getBoundingClientRect();
      cursorRef.current!.style.backgroundSize = `${fgRect.width}px ${fgRect.height}px`;
      cursorRef.current!.style.backgroundPosition = `-${x - cursorSize}px -${y - cursorSize}px`;
    };

    containerRef?.current?.addEventListener('mousemove', handleMouseMove);

    return () => {
      containerRef?.current?.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return {
    cursorRef,
    containerRef,
    foregroundRef,
  };
};
