import clsx from 'clsx';

interface IProps {
  classname?: string;
}

export const PolygonsGroup = ({ classname }: IProps) => {
  return (
    <div
      className={clsx(
        `
          w-[899px] h-[1022px]
          position absolute top-0 right-0
          bg-contain bg-no-repeat bg-center pointer-events-none
        `,
        {
          [`${classname}`]: classname,
        },
      )}
      style={{ backgroundImage: `url('/backgrounds/polygons-group.svg')` }}
    ></div>
  );
};
