'use client';

import { useCursor } from '@/hooks/useCursor';
import './styles.scss';
import { useState } from 'react';
import clsx from 'clsx';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import Image from 'next/image';

interface IProps {
  foregroundImage: string;
  backgroundImage: string;
  imageClassName?: string;
}

export const Cursor = ({ foregroundImage, backgroundImage, imageClassName }: IProps) => {
  const { cursorRef, foregroundRef, containerRef } = useCursor();
  const [isHover, setHover] = useState(false);
  const isInViewPort = useIsInViewport(containerRef);

  return (
    <div
      ref={containerRef}
      onMouseOver={() => setHover(true)}
      className="relative max-w-[700px] z-[1] xl:right-[-80px]"
    >
      <Image
        ref={foregroundRef}
        src={foregroundImage}
        className={clsx('block w-full', {
          [`${imageClassName}`]: imageClassName,
        })}
        alt="Foreground"
        width={700}
        height={456}
      />

      <div
        ref={cursorRef}
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className={clsx('cursor', {
          ['cursorLoad']: !isHover && isInViewPort,
        })}
      ></div>
    </div>
  );
};
